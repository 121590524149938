.app__header {
  background-image: url("../../assets/front.webp");
  min-height: 85dvh;
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__header-hr {
  width: 80px;
  border-style: none;
  background-color: black;
  height: 2px;
  margin-left: 0px;
}

.app__wrapper_start {
  background-color: #e3d4bf;
  padding: 30px 30px;
  border-radius: 3px;
}

.app__header-h1 {
  font-family: var(--font);
  font-weight: 400;
  line-height: 100px;
  font-size: 72px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.app__header-h2 {
  margin-top: 0px;
}

.app__header-Welcome {
  color: var(--red-color);
  margin: 0;
  font-size: 96px;
  font-weight: 700;
  font-weight: 400;
}

h2 {
  font-family: var(--sub-font);
}

.app__header-img img {
  width: 80%;
}

@media screen and (min-width: 2000px) {
  .app__header-h1 {
    font-size: 120px;
    line-height: 210px;
  }
  .app__wrapper_info {
    padding: 100px 50px;
  }
}

@media screen and (max-width: 1150px) {
  .app__header {
    height: auto;
  }
  .app__wrapper_info {
    padding: 10px;
    max-height: 450px;
  }
  .app__header-h1 {
    font-size: 85px;
  }
  .app__header-Welcome {
    font-size: 100px;
  }
}

@media screen and (max-width: 790px) {
  .app__header {
    min-height: 75vh;
  }
  .app__wrapper_info {
    flex: none;
    max-height: 400px;
    padding: 18px;
  }
  .app__header-h1 {
    font-size: 52px;
    line-height: 87px;
  }

  .app__header-Welcome {
    font-size: 70px;
  }
}

@media screen and (max-width: 490px) {
  .app__header-h1 {
    font-size: 35px;
    line-height: 45px;
    padding-bottom: 10px;
  }
  .app__header-Welcome {
    font-size: 50px;
  }
}
