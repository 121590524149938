.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 0px;
  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 70%;
}

.section__padding {
  padding: 4rem 6rem;
  /* padding-top: 0;
  padding-bottom: 0; */
}

.custom__button {
  background-color: var(--red-color);
  color: white;
  font-family: var(--navbar-font);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.custom__button:hover {
  transform: translateY(-2px);
}

.app__hr {
  width: 80px;
  border-style: none;
  background-color: black;
  height: 2px;
  margin-bottom: 30px;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

.app__h1 {
  font-family: var(--navbar-font);
  font-weight: 400;
  line-height: 90px;
  font-size: 40px;

  margin-top: 25px;
  margin-bottom: 10px;

  text-align: center;
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 3rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }

  .section__padding {
    padding: 2rem 2rem;
  }
  .app__h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 2rem 2rem;
  }
  .app__h1 {
    font-size: 20px;
    line-height: 45px;
    margin-top: 20px;
  }
  .app__wrapper_img {
    width: 90%;
  }
}
