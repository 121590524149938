@font-face {
  font-family: Lexend;
  src: url("../public/fonts/Lexend-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Marcellus SC;
  src: url("../public/fonts/MarcellusSC-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Aleo;
  src: url("../public/fonts/Aleo-Regular.ttf") format("truetype");
  
}

body {
  max-width: 1920px;
  margin: 0 auto !important;
  padding:0;
  overflow-x:hidden;
  float: none !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  --navbar-font: 'Lexend', sans-serif;
  --sub-font: 'Marcellus SC', serif;
  --font: 'Aleo', serif;
  
  --primary-color: #E3D4BF;
  --secondary-color: #93A8A3;
  --third-color: #D79A60;
  --red-color: #B33B35;

  scroll-behavior: smooth;
}

body {
  max-width: 2560px;
  margin: 0 auto !important;
  float: none !important;
}