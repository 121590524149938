.app__footer-wrapper {
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-evenly;
  max-width: 100%;

  font-family: var(--navbar-font);
  text-align: center;
}

.app__footer-logo {
  display: flex;
  justify-content: flex-start;
}

.app__footer-logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__footer-logo {
  max-width: 250px;
  height: auto;
}

.app__footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app__footer-container p {
  font-family: var(--navbar-font);
  width: 100px;
  height: 80px;
}

.middle {
  text-align: center;
}

.verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.right a {
  text-decoration: none;
  color: black;
}

.app__footer-out {
  background-color: var(--primary-color);
}

.app__hr-footer {
  display: none;
  width: 180px;
  border-style: none;
  background-color: black;
  height: 2px;
  margin-bottom: 10px;
  margin-top: 0;
}

@media screen and (max-width: 950px) {
  .app__footer-logo {
    display: none;
  }
  .app__footer-wrapper {
    display: flex;
    justify-content: center;
    gap: 30px;

    font-size: 10px;
  }
  .app__footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: center;
  }
  .text, .middle {
    display: none;
  }
  .app__footer-container p {
    width: 200px;
    height: 50px;
  }
  .app__hr-footer {
    display: block;
  }
}