.app__aboutUs {
  background-color: var(--third-color);
  /* min-height: 85vh; */
  padding-bottom: 50px;
  padding-top: 1px;
  text-align: justify;
}

.as {
  background-color: none;
}

.app__aboutUs-img {
  margin-top: 30px;
}

.app__aboutUs-h1 {
  font-family: var(--font);
  font-weight: 400;
  line-height: 20px;
  font-size: 22px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.app__aboutUs-Welcome {
  color: var(--red-color);
  font-size: 56px;
  font-weight: 700;
}

h2 {
  font-family: var(--sub-font);
}

.app__header-img img {
  width: 80%;
}

@media screen and (max-width: 1750px) {
  .app__aboutUs-h1 {
    font-size: 20px;
    line-height: 18px;
  }

  .app__aboutUs-Welcome {
    font-size: 42px;
  }
}

@media screen and (max-width: 1150px) {
  .app__aboutUs {
    padding-bottom: 20px;
  }

  .app__header {
    height: auto;
  }

  .app__aboutUs-h1 {
    font-size: 20px;
    line-height: 25px;
  }

  .app__aboutUs-Welcome {
    font-size: 32px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 650px) {
  .app__aboutUs-h1 {
    font-size: 15px;
    line-height: 15px;
  }

  .app__aboutUs-Welcome {
    font-size: 32px;
  }

  .app__aboutUs-img {
    margin-top: 0;
  }
}

@media screen and (max-width: 450px) {}