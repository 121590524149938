.app__contact-wrapper {
  background-color: var(--third-color);
  display: flex;
  flex-wrap: wrap;

  height: 65dvh;
}

.app__contact-info {
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-family: var(--navbar-font);
  line-height: 30px;
  font-size: 25px;
}

.app__contact-info a {
  text-decoration: none;
  color: black;
}

.app__contact-mapWrapper {
  width: 50%;
  height: 100%;
  right: 0;
}

.app__contact-map {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

@media only screen and (max-width: 650px) {
  .app__contact-wrapper {
    padding: 2rem 1rem;
  }

  .app__contact {
    min-height: 65dvh;
  }

  .app__contact-info {
    height: 30dvh;
    width: 100%;
    
    font-size: 20px;
    text-align: center;
  }

  .app__contact-mapWrapper {
    width: 100%;
    height: 50%;
  }

  .app__contact-map {
    width: 100%;
    border-radius: 6px;
  }
}