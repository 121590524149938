.margin {
  margin: 0 0;
}

.app__openingHours-bg {
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/eistheke.webp");
}

.app__openingHours-wrapper {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__openingHours-data {
  background-color: var(--third-color);
  height: 350px;
  width: 600px;

  /* box-shadow: 0px 0px 15px rgb(108, 108, 108); */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  line-height: 50px;
}

.app__openingHours-data h1 {
  font-family: var(--font);
  font-weight: 800;
}

.app__openingHours-data h2 {
  font-family: var(--font);
  font-weight: 400;
}

@media screen and (max-width: 1550px) {
  .app__openingHours-bg {
    height: 600px;
    background-image: url("../../assets/eisthekeSmall.webp");
    background-size: auto;
    /* background-position: top; */
  }
}

@media screen and (max-width: 850px) {
  .app__openingHours-data {
    height: 250px;
    width: 450px;
    line-height: 20px;
  }
}

@media screen and (max-width: 650px) {
  .app__openingHours-data {
    height: 150px;
    width: 250px;
    line-height: 20px;
  }

  .app__openingHours-data h1 {
    font-size: 20px;
  }

  .app__openingHours-data h2 {
    font-size: 16px;
  }
  .app__openingHours-bg {
    height: 400px;
    max-width: 100%;
    background-image: url("../../assets/EIS.JPG");
    background-size: 700px;
    background-attachment: local;
  }
}
