.app__iceCream {
    background-color: var(--secondary-color);
    
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    padding: 0px;
}

.app__iceCream-img {
    width: 100%;
    margin-bottom: 50px;
    max-height: 400px;
    object-fit: contain;
}

@media screen and (max-width: 1150px) {
}

@media screen and (max-width: 650px) {
    .app__iceCream-img {
        width: 100%;
        margin-bottom: 10px;
    }
}