.info__container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    max-width: 100%;
    padding: 4rem 6rem;
    background-color: var(--third-color);
}

.info__information-container {
    background-color: var(--primary-color);
    border-radius: 3px;
    padding: 20px;
    width: 45%;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.info__header {
    font-family: var(--navbar-font);
    font-size: 32px;
}

.info__text p {
    font-family: var(--navbar-font);
    font-size: 18px;
    margin-bottom: 0;
}

.bold-text {
    font-weight: 700;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
}

@media screen and (max-width: 650px) {
    .info__container {
        flex-direction: column;
        padding: 2rem 1rem;
    }

    .info__information-container {
        width: auto;
    }

    .info__text {
        font-size: 16px;
    }
}