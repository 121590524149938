.app__food-wrapper {
  background-color: var(--primary-color);
  padding-top: 20px;
  margin: 0px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.test {
  background-color: aquamarine;
  padding: 5px 8px;
  box-shadow: 0px 0px 11px rgb(146, 146, 146);
  height: 30px;
  width: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testt {
  background-color: black;
  padding: 5px 8px;
  box-shadow: 0px 0px 11px rgb(146, 146, 146);
  height: 30px;
  width: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.food-image {
  width: 100%;
}

.app__food-nav ul {
  background-color: var(--primary-color);
  padding: 0px 40px;
  padding-top: 50px;
  margin: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.app__food-nav li {
  list-style: none;
  text-decoration: none;
}

.link {
  text-decoration: none;
}

.s {
  width: 80px;
  padding: 5px 8px;
  box-shadow: 0px 0px 11px rgb(146, 146, 146);
  /* height: 30px; */
  /* width: 25px;  */
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 670px) {
  .app__food-wrapper {
    grid-template-columns: 100%;
  }
  .app__food-nav ul {
    padding: 0px 0px ;
    padding-top: 20px;
  }
}
