.app__scrollUp {
    position: fixed;
    right: 10px;
    bottom: 10px;

    width: 35px;
    height: 35px;
    border-radius: 100% ;

    border: none;

    z-index: 5;

    background-color: var(--primary-color);

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;

    transition: 2s all ease;

    box-shadow: 0px 0px 10px rgb(177, 177, 177);
}

.app__scrollUp:hover {
    cursor: pointer;
    background-color: rgb(212, 185, 149);
}

.app__scrollUp-icon {
    color: black;
}

@media screen and (min-width: 650px) {
    .app__scrollUp {
        width: 50px;
        height: 50px;
    }
  }