.app__gallery-wrapper {
  background-color: var(--secondary-color);
  padding: 1px 5px;
  padding-bottom: 20px;
  height: 800px;
}

.slider {
  position: relative;
  max-width: 100%;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-height: 550px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 6px;
  object-fit: contain;
}

.right-arrow {
  position: absolute;
  bottom: 45%;
  right: 10%;
  font-size: 3rem;
  color: #000;
  z-index: 2;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  bottom: 45%;
  left: 10%;
  font-size: 3rem;
  color: #000;
  z-index: 2;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

@media screen and (max-width: 1150px) {
  .image {
    max-height: 400px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .app__gallery-wrapper {
    height: auto;
  }
  .right-arrow {
    position: absolute;
    bottom: 50%;
    right: 0px;
  }
  .left-arrow {
    position: absolute;
    bottom: 50%;
    left: 0px;
  }
  .slider {
    height: 500px;
  }
}

@media screen and (max-width: 670px) {
  .image {
    max-height: 200px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .app__gallery-wrapper {
    height: auto;
  }
  .right-arrow {
    position: absolute;
    bottom: 50%;
    right: 0%;
  }
  .left-arrow {
    position: absolute;
    bottom: 50%;
    left: 0%;
  }
  .slider {
    height: 200px;
  }
}

@media screen and (max-width: 450px) {
  .image {
    max-width: 90%;
  }
}
