.app_navbar {
  width: 100%;
  max-height: 100px;
  height: 100px;
  display: flex;
  justify-content: space-evenly;

  background-color: var(--primary-color);
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.app__navbar-logo img {
  width: 280px;
  margin-top: 20px;
  margin-bottom: 20px ;
}

.app__navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;

  font-family: var(--navbar-font);
}

.app__navbar-links li {
  margin: 0 2rem;
  text-transform: uppercase;
}

.app__navbar-links a {
  text-decoration: none;
  color: black;
}

.app__navbar-links li:hover {
  border-bottom: 2px solid black;
  transition: all 0.2s linear;
}

#app__navbar-line {
  border-left: 2px solid #000;
  height: 30px;
  right: 10%;
}

#app__navbar-facebook {
  width: 45px;
  margin-left: 25px;
}

.app__navbar-mobile {
  display: none;
}

.app__navbar-mobile_overlay {
  position: fixed;

  display: flex;
  justify-content: center;

  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--secondary-color);

  flex-direction: column;
  z-index: 15;

  animation: fade 0.2s 0ms;
}

.app__navbar-mobile_overlay .overlay__close {
  font-size: 27px;
  color: var(--color-black);
  cursor: pointer;

  position: absolute;
  top: 30px;
  right: 20px;
}

.app__navbar-mobile_links {
  list-style: none;
  text-align: center;
  padding: 0;
  text-transform: uppercase;
}

.app__navbar-mobile_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-golden);
  font-size: 2rem;
  text-align: center;
  font-family: var(--navbar-font);
}

.app__navbar-mobile_links li:hover {
  border-bottom: 3px solid black;
}

.app__navbar-mobile_links a {
  text-decoration: none;
  color: black;
}

.app__navbar-mobile-burger {
  font-size: 27px;
  color: var(--color-black);
  cursor: pointer;

  position: fixed;
  top: 30px;
  right: 20px;
  z-index: 10;
}

#app__navbar-mobile_facebook {
  margin-top: 20px;
}

@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
    width: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .app__navbar-links {
    display: none;
  }

  .app__navbar-mobile {
    display: flex;
  }

  .app__navbar-logo {
    margin-top: -12px;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem;
  }

  .app__navbar-login {
    display: none;
  }

  .app__navbar-logo img {
    width: 210px;
  }
  .app__navbar-logo {
    margin-top: 0px ;
  }
}
@-webkit-keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
} 

@keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
}